<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>User - Role</template>
    <template #body>
      <FormText
        v-if="form.role != null"
        label="Rol Actual"
        icon="fa-solid fa-chess"
        v-model="form.role.role_name"
        disabled
      />
      <FormSelect
        label="Rol a Actualizar"
        icon="fa-solid fa-chess"
        v-model="selOpt"
        :options="roles"
      />
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData" type="submit">Actualizar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormSelect from "@/components/Form/FormSelect.vue";
import FormText from "@/components/Form/FormText.vue";

export default {
  components: {
    FormSelect,
    FormText
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selOpt: null,
    form: null,
  }),
  computed: {
    roles() {
      return this.$store.getters.roles.data.map((role)=>({
        value:role.id,
        name:role.role_name
      }));
    },
  },
  async mounted() {
    this.getRoles();
    if (this.item) {
      this.form = {...this.item};
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getRoles(){
      this.$store.dispatch("getRoles");
    },
    saveData() {
      const id = this.item.id
      const form = {
        roleId: this.selOpt,
      }
      this.$store
          .dispatch("updateUser", {id, form})
          .then(() => {
            this.close();
          });
          this.$emit('saved')
    },
  },
};
</script>
<style></style>
