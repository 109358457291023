<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>User - Persona ID</template>
    <template #body>
      <FormText
        v-if="itemCopy.persona_id"
        label="Persona ID Actual"
        icon="fa-solid fa-address-card"
        v-model="itemCopy.persona_id"
        disabled
      />
      <FormSwitch
        label="Desea Crear una Persona Nueva ?"
        icon="fa-solid fa-question"
        v-model="newPersonOpt"
      />
      <template v-if="newPersonOpt === true">
      <!-- Se deja Vacio, porque no necesito ninguna informacion para crear Persona -->
      </template>
      <template v-else>
        <FormAutoComplete 
          label="Persona a vincular con User"
          icon="fa-solid fa-address-card"
          v-model="personaDeLaLista"
          :data="listaPersonas"
        />
      </template>
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData" type="submit">Enviar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormSwitch from "@/components/Form/FormSwitch.vue";
import FormText from "@/components/Form/FormText.vue";
import FormAutoComplete from "../../../components/Form/FormAutoComplete.vue";

export default {
  components: {
    FormSwitch,
    FormText,
    FormAutoComplete,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    itemCopy: null,
    personaDeLaLista: null,
    newPersonOpt: false,
  }),
  computed: {
     listaPersonas(){
      if (!this.$store.getters.listaPersonas) return []
      return this.$store.getters.listaPersonas.map(p => `${p.id} - ${p.nombre}`)
    },
  },
  async mounted() {
    this.$store.dispatch('getListaPersonas');
    if (this.item) {
      this.itemCopy = {...this.item};
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveData() {
      if (this.newPersonOpt === 1){
        const form = {
          nombre: `${this.itemCopy.first_name} ${this.itemCopy.last_name}`,
          email: this.itemCopy.email
        }
        this.$store
          .dispatch("registerPersona", form)
          .then((id) => {
            this.actualizarPersonaId(id);
          });
      }else{
        const id = parseInt(this.personaDeLaLista?.split('-')[0]?.trim()|| null)
         this.actualizarPersonaId(id);
      }
    },
    actualizarPersonaId(personaId){
      const id = this.itemCopy.id
      const form = {persona_id: personaId}
      this.$store
          .dispatch("updatePersonaId", {id, form})
          .then(() => {
            this.close();
          });
          this.$emit('saved')
    }
  },
};
</script>
<style></style>
