<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Users</template>
        <button v-if="canPost" @click="goToEventsCreateUser()" class="btn btn-block btn-medium">NUEVO USUARIO</button>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay usuarios para mostrar` : `No tienes permisos para ver usuarios`"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onUpdatePersonaId="onUpdatePersonaId"
          @onUpdateItem="onUpdateItem"
          @onDeleteItem="onDeleteItem"
        />
        <div v-if="canGetDUSER" class="text-center mt-3"><button class="btn btn-dark float-right" @click="goToEvents()">Usuarios Eliminados</button></div>
      </Widget>
    </div>  
    <UserModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    /> 
    <RoleEditModal
      v-if="showRoleModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
    <PersonaIdEditModal
      v-if="showPersonaIdModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
    <UserCreateModal
      v-if="showCreateModal"
      @close="closeModal"
      @saved="onSaveItem"
    /> 
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import UserModal from "./UserModal.vue"
import RoleEditModal from "./RoleEditModal.vue"
import UserCreateModal from "./UserCreateModal.vue"
import PersonaIdEditModal from "./PersonaIdEditModal.vue"
import {can, canPG} from '@/acl/methods'
export default {
  components: {
    Widget,
    UserModal,
    RoleEditModal,
    PersonaIdEditModal,
    UserCreateModal,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "id",
          mask: "Id",
          sortable: true,
        },
        {
          title: "first_name",
          mask: "Nombre",
          sortable: true,
        },
        {
          title: "last_name",
          mask: "Apellido",
          sortable: true,
        },
        {
          title: "email",
          mask: "Email",
          sortable: true,
        },
        {
          title: "role.role_name",
          mask: "Rol",
          sortable: true,
        },
        {
          title: "persona_id",
          mask: "Id Persona",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Mostrar Detalles",
          icon: "fa-solid fa-eye",
        },
        {
          buttonClass: "btn-primary",
          callback: "onUpdatePersonaId",
          tooltip: "Actualizar ID Persona",
          icon: "fa-solid fa-address-card",
        },
        {
          buttonClass: "btn-warning",
          callback: "onUpdateItem",
          tooltip: "Editar Rol",
          icon: "fa-solid fa-pencil",
        },
        {
          buttonClass: "btn-danger",
          callback: "onDeleteItem",
          tooltip: "Borrar User",
          icon: "fa-solid fa-trash",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    showRoleModal: false,
    showPersonaIdModal: false,
    showCreateModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.users;
    },
    canGetDUSER(){
      return (can(this.$store.getters.user, 'users', ['get']) && canPG(this.$store.getters.user, 'g_d_user'))
    },
    canGet(){
      return can(this.$store.getters.user, 'users', ['get'])
    },
    canPost(){
      return can(this.$store.getters.user, 'users', ['post'])
    },
    canPut(){
      return can(this.$store.getters.user, 'users', ['put'])
    },
    canDel(){
      return can(this.$store.getters.user, 'users', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      if (!this.canGet) {
        actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      }
      if (!this.canPost && !this.canPut){
        actions.splice(actions.findIndex(a => a.callback === 'onUpdatePersonaId'), 1)
      }
      if (!this.canPut){
        actions.splice(actions.findIndex(a => a.callback === 'onUpdateItem'), 1)
      }
      if (!this.canDel) {
        actions.splice(actions.findIndex(a => a.callback === 'onDeleteItem'), 1)
      }
      return actions
    }
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showCreateModal = false;
      this.showModal = false;
      this.showRoleModal = false;
      this.showPersonaIdModal = false;
    },
    getItems(params) {
      this.$store.dispatch("getUsers", params);
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    onUpdatePersonaId(item){
      this.showPersonaIdModal = true;
      this.selItem = item;
    },
    onUpdateItem(item) {
      this.showRoleModal = true;
      this.selItem = item;
    },
    onDeleteItem(item) {
      this.$alert
        .fireConfirm("Borrar User", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteUser", item.id).then(() => {
           this.onSaveItem();
        });
        });
    },
    goToEvents: function () {
        this.$router.push('/usersdeleted')
    },
     goToEventsCreateUser(item) {
        this.showCreateModal = true;
        this.selItem = item;
    },
  },
};
</script>

<style>

</style>