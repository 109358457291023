<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Crear Usuario</template>
    <template #body>
      <form @submit.prevent="createPersona">
        <FormText
            label="Nombre"
            v-model="form.first_name"
          />
          <FormText
            label="Apellido"
            v-model="form.last_name"
          />
          <FormText
            label="Email"
            v-model="form.email"
          />
          <FormText
            label="Password"
            password
            v-model="form.password"
          />
          <FormText
            label="Repetir Password"
            password
            v-model="form.password_confirmation"
          />
          <FormSwitch
            label="Desea Crear una Persona Nueva ?"
            icon="fa-solid fa-question"
            v-model="newPersonOpt"
          />
          <template v-if="newPersonOpt === true">
          <!-- Se deja Vacio, porque no necesito ninguna informacion para crear Persona -->
          </template>
          <template v-else>
            <FormAutoComplete 
              label="Persona a vincular con User"
              icon="fa-solid fa-address-card"
              v-model="personaDeLaLista"
              :data="listaPersonas"
            />
          </template>
      </form> 
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="createPersona">
        Crear
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormSwitch from "@/components/Form/FormSwitch.vue"
import FormAutoComplete from "../../../components/Form/FormAutoComplete.vue";
export default {
  components: {
    FormText,
    FormAutoComplete,
    FormSwitch,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    personaDeLaLista: null,
    newPersonOpt: false,
    form: {
      first_name: null,
      last_name: null, 
      email: null,
      password: null,
      password_confirmation: null,
      persona_id: null,
    },
  }),
  computed: {
     listaPersonas(){
      if (!this.$store.getters.listaPersonas) return []
      return this.$store.getters.listaPersonas.map(p => `${p.id} - ${p.nombre}`)
    },
  },
  async mounted() {
    this.$store.dispatch('getListaPersonas');
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    createPersona(){
      if (this.newPersonOpt === 1){
        const form = {
          nombre: `${this.form.first_name} ${this.form.last_name}`,
          email: this.form.email
        }
        this.$store
          .dispatch("registerPersona", form)
          .then((id) => {
            this.$store.dispatch('getListaPersonas')
            return id;
          }).then((id)=>{
            this.createUser(id)
          });
      }else{
        const id = parseInt(this.personaDeLaLista?.split('-')[0]?.trim()|| null)
         this.createUser(id);
      }
    },
    async createUser(personaId){
      this.form.persona_id = personaId
      this.$store
         .dispatch("register", this.form)
         .then(()=>{
          this.close()
         }) 
         this.$emit('saved')
      }
  },
};
</script>
<style></style>
